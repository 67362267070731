import { createMuiTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'

// Create a theme instance.
const Theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ff855d',
      main: '#ee3d02',
      dark: '#c82400',
      contrastText: '#fff',
    },
    secondary: {
      light: '#7cd2f4',
      main: '#02b3ee',
      dark: '#0074b6',
      contrastText: '#000',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fafafa',
    },
  },
})

export default Theme
