import React from 'react'
import App from 'next/app'
import Head from 'next/head'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Theme from '../components/Theme'

import css from 'styled-jsx/css'

const html = css.global`html { scroll-behavior:smooth; background-color:#fafafa; }`

class MyApp extends App {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentNode!.removeChild(jssStyles)
    }
  }

  render() {
    const { Component, pageProps } = this.props

    return (
      <>
        <Head>
          <meta charSet="utf-8" />
          {/* Use minimum-scale=1 to enable GPU rasterization */}
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
          {/* PWA primary color */}
          <meta name="theme-color" content={Theme.palette.primary.main} />

          {/*
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700;subset=latin-ext&display=swap"
          />
          */}

          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://ajax.googleapis.com" />

          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ee3d02" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ee3d02" />

          <meta property="og:site_name" content="HotPrimes" />
          <meta property="og:type" content="website" />

          <meta name="apple-mobile-web-app-title" content="HotPrimes" />
          <meta name="application-name" content="HotPrimes" />
          <meta name="author" content="Tobias Ackermann" />

          <title>HotPrimes</title>
        </Head>

        <style jsx global>{html}</style>

        <ThemeProvider theme={Theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...pageProps} />
        </ThemeProvider>
      </>
    )
  }
}

export default MyApp
